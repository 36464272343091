<template>

<body>
<!-- preloader -->
<Loader> </Loader>
<!-- preloader -->
    
  <div class="site-wrapper overflow-hidden">
  <Front-Header> </Front-Header>

    
    <!--main-->
            <main class="thankyou_main">
      <div class="inner">
        <img class="mainImg" src="../../assets/front/image/undraw_newsletter_re_wrob.svg" alt="" />
        <div class="modal2">
          <div class="modal__icon">
            <img width="90" src="../../assets/front/image/mailing.png" alt="Icon of Mailbox" />
          </div>
          <h1 class="modal__heading">thank you!</h1>
          <p>
           {{ this.description }}
          </p>
          <router-link :to="{name: 'Home'}">Back To Home</router-link>
        </div>
      </div>
    </main>
    <!--main-->

    
    <!-- Footer section -->
    <Front-Footer> </Front-Footer>

  </div>

</body>
</template>
<script>
import Header from './Front-Header';
import Footer from './Front-Footer';
import Loader from './Loader';
import { db } from '@/main'


export default {
   components:{
    'Front-Header':Header,
    'Front-Footer':Footer,
    'Loader':Loader,
  },

  data(){
      return{
      
        companies: [],
        offers: [],
        locations: [],
        subscription:{},
        count:'',  
        item:'',  
      }
  },

  created: function()
  {
      this.getCompany();
  },

 
methods: {

async getCompany()
    {
        //var id = this.$route.params.id;
      // var  id = this.$route.query.id
        
      await db.collection('email_management').doc("X2IX7gaa2F5tL77y6dnn").get().then( async (doc) => {  
            this.item = doc.data().Name;
            //this.item.id = doc.id;

        });
    },
 
  }
}
</script>
<style>
       .thankyou_main {
        font-size: 10px;
        line-height: 1.42857143;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100vh;
        position: relative;
        /* background-image: linear-gradient(to top, #09203f 0%, #1c3e58 100%); */
        background: #03e1bc94;
        font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
          Roboto, "Helvetica Neue", Arial, sans-serif;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        padding: 18vh 0;
      }
      .thankyou_main .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .thankyou_main .inner .mainImg {
        max-width: 50%;
        margin-bottom: 80px;
      }
      .thankyou_main .modal2 {
        background: #03e1bc;
        color: #ffffff;
        width: 33rem;
        border-radius: 4px;
        text-align: center;
        padding: 50px;
        position: relative;
        min-height: 30vh;
      }
      .thankyou_main .modal__icon {
        position: absolute;
        top: -2rem;
        left: 50%;
        margin-left: 0;
        background: #03e1bc;
        padding: 3rem 12rem 0 12rem;
        border-radius: 50%;
        transform: translateX(-50%);
      }
	  .thankyou_main .modal__icon img{
		    max-width: initial;
	  }
      .thankyou_main .modal__heading {
        text-transform: uppercase;
        font-size: 35px;
        margin-top: 60px;
        margin-bottom: 0;
		color:#fff;
      }
      .thankyou_main .modal2 p {
        font-size: 16px;
        margin-top: 0;
		color:#fff;
      }
      .thankyou_main .email-box {
        position: absolute;
        bottom: -3rem;
        left: 50%;
        margin-left: -20rem;
        width: 40rem;
      }
      .thankyou_main .modal2 a {
        font-size: 20px;
        margin-top: 20px;
        display: block;
        color: #15334f;
        text-decoration: none;
      }

      .thankyou_main .modal a:hover {
        color: #f88300;
      }

      @media (max-width: 1440px) {
        .thankyou_main .modal2 {
          width: 35rem;
          padding: 40px;
        }
        .thankyou_main .inner .mainImg {
          max-width: 40%;
          margin-bottom: 70px;
        }
      }

      @media only screen and (max-width: 600px) {
        .thankyou_main .modal2 {
          width: 93vw;
        }
        .thankyou_main .modal__icon {
          padding: 3rem 6rem 0 6rem;
          border-radius: 50%;
        }
        .thankyou_main .inner .mainImg {
          max-width: 70%;
        }
      }
 </style>